
export  const nichetype1 = [
  { label: 'Space' },
  { label: 'war' },
  { label: 'ocean' },
  { label: 'island' },
  { label: 'animal' },
  { label: 'world record' },
  { label: 'rocket' },
  { label: 'educational institute' },
  { label: 'vehicle' },
  { label: 'job' },
  {label:'solar system'},
  {label:'mars'},
  {label:'exoplanet'},
  {label:'country'},
  {label:'ISRO'},
  {label:'foreign country'},
  {label:'satellite'},
  {label:'scientist'},
  { label: 'sports' },
  { label: 'IPL' },
  {label:'technology'},
];